import React from "react";
import "./Header.css";
import { Link, useHistory } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";

function Header() {
  const [{ user }, dispatch] = useStateValue();
  const history = useHistory();

  const handleAuthenticaton = () => {
    if (auth.currentUser) {
      auth.signOut();
      history.push("/login");
    }
  };

  return (
    <div className="header__border">
      <div className="header">
        <Link to="/">
          <img
            className="header__logo"
            src="https://firebasestorage.googleapis.com/v0/b/dakaibox-94cce.appspot.com/o/dakaibox.jpg?alt=media&token=c13f1b03-625d-4126-a480-5117d4739da3"
          />
        </Link>

        <div className="header__nav">
          <Link to={!user && "/login"} style={{ textDecoration: "none" }}>
            <div onClick={handleAuthenticaton} className="header__option">
              <span className="header__optionLineOne">
                {!user ? "Guest" : user.email.replace(/@(.*)/, "")}
              </span>
              <span className="header__optionLineTwo">
                {user ? "登出" : "登入"}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
