import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDFiHeYeS3BSC1jM6bh5fsJGxTFIPfzZso",
  authDomain: "dakaibox-94cce.firebaseapp.com",
  // databaseURL: "https://dakaibox-94cce.firebaseio.com",
  projectId: "dakaibox-94cce",
  storageBucket: "dakaibox-94cce.appspot.com",
  messagingSenderId: "447007817899",
  appId: "1:447007817899:web:aaf2910eaf09820f10f7ee",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
