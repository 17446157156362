import React, { useEffect } from "react";
import "./Home.css";
import { useHistory } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";

function Home() {
  const [{}, dispatch] = useStateValue();
  const history = useHistory();
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // the user just logged in / the user was logged in

        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        // the user is logged out
        dispatch(
          {
            type: "SET_USER",
            user: null,
          },
          history.push("/login")
        );
      }
    });
  }, []);

  return <div className="home">大家怎看</div>;
}

export default Home;
