import React, { useState } from "react";
import "./Login.css";
import { Link, useHistory } from "react-router-dom";
import { auth } from "./firebase";
import { useStateValue } from "./StateProvider";

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (e) => {
    e.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .then((authUser) => {
        history.push("/");
      })
      .catch((error) => alert(error.message));
  };

  const register = (e) => {
    e.preventDefault();

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        // it successfully created a new user with email and password
        if (authUser) {
          history.push("/");
        }
      })
      .catch((error) => alert(error.message));
  };

  return (
    <div className="login">
      <img
        className="login__logo"
        alt=""
        src="https://firebasestorage.googleapis.com/v0/b/dakaibox-94cce.appspot.com/o/dakaibox.jpg?alt=media&token=c13f1b03-625d-4126-a480-5117d4739da3"
      />

      <div className="login__container">
        <h1>登入</h1>

        <form>
          <h5>Email</h5>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <h5>密碼</h5>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button
            type="submit"
            onClick={signIn}
            className="login__signInButton"
          >
            <span>登入</span>
          </button>
        </form>
        <p>
          當您開始使用 DaKaiBox
          服務，即表示您信賴我們對您個人資訊的處理方式。我們深知這份責任重大，因此會盡力保護您的資訊，並為您提供相關的管理功能。
        </p>
        <h5>
          <span>或</span>
        </h5>
        <button onClick={register} className="login__registerButton">
          <span>註冊</span>
        </button>
      </div>
    </div>
  );
}

export default Login;
